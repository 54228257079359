import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { pipesToSpan } from "../util/common"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import HubspotForm from "react-hubspot-form";
import useFooter from "../hooks/useFooter"

function Landing({ data: { node }, pageContext: { isThankYou }, path }) {

  //console.log( node.calendarUrl)
  //console.log( node.relationships.thankyou)
  //console.log( 'isThankYou=' , isThankYou)

  //console.log('path=',path)

  const {
    title1,
    subtitle,
    section3Title,
    relationships,
    description,
    calendarUrl
    //ctaButton,
    //ctaBody
  } = node

  const { hubspot,  blocks, section3Items, testimonial, thankyou } = relationships

  //console.log(hubspot)

  //hubspot.path = path

  const logos = relationships.logos.map((item,index) => (
    {...item, alt: node.logos[index].alt}
  ))

  const seo =  getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  )

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  return (
    <Layout showCta={false} showMenus={false} showFooterMenus={true} showHeader={false} showFooter={false}>
      <Seo {...seo} />

      { ! isThankYou &&
        <>
          <Hero
            title={title1}
            subtitle={subtitle}
            description={description}
            hubspot={{...hubspot, path:path}}
            items={[]}
            logos={logos}
            >
            <Hubspot {...{...hubspot, path:path}} />
          </Hero>

          <Section1 data={testimonial}  />
          <Section3 items={section3Items} title={section3Title}/>
          <Section2 items={blocks} />
      </>
    }

    {  isThankYou &&
      <>
        <Hero
          title={thankyou.title}
          subtitle={[]}
          description={thankyou.description_html.processed}
          hubspot={{...hubspot, path:path}}
          items={[]}
          logos={[]}
          >
            <iframe title="Calendar" src={calendarUrl} width={'100%'} height={'700px'} className="bg-white pb-16 " />
            <div className="h-12 lg:h-0" />
          </Hero>
      </>
    }

      <SectionFooter  />

      {  ! isThankYou &&
        <>
          <div className="lg:hidden h-20" />
          <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white px-6 py-4 bg-white z-[1001]">
            <a href="#the-form" className="p-3 w-full block text-center bg-blue text-white border rounded-full font-bold">
              Book A Demo
            </a>
          </div>
        </>
      }

    </Layout>
  )
}

const Hero = ({title,subtitle,description, hubspot, items, logos, children}) => {

  const titleHtml = pipesToSpan(title, 'text-[#FFCC00] font-bold')

  return (
    <section className="bg-dark-blue text-white pt-16 lg:pt-32 lg:pb-16 " >
      <div className="container px-0 lg:px-6" >
        <div className="flex flex-col lg:flex-row justify-between  w-full space-y-12 lg:space-y-0">
          <div className="lg:w-7/12 space-y-5 lg:space-y-10 lg:w-[708px] px-6 lg:px-0">
            <Link to="/">
              <img src='/Logo-horizontal-gradblue.svg' alt="sigthfull" />
            </Link>
            <h1 className={"text-4xl lg:text-6xl lg:leading-[65px] font-normal  " } dangerouslySetInnerHTML={{ __html: titleHtml }} />

            <h2 className="text-lg lg:text-2xl   " dangerouslySetInnerHTML={{ __html: subtitle }} />
            <div className="text-lg lg:text-lg " dangerouslySetInnerHTML={{ __html: description }} />

            <div className="space-y-5 lg:space-y-10">
              {items.map((item, index) =>(
                <div className="flex flex-col lg:flex-row justify-start" key={index}>
                  <div className=" lg:w-24">
                    <img src={item.relationships.image.localFile.publicURL} width={40} alt={item.image.alt} />
                  </div>
                  <div className="w-10" />
                  <div className="text-lg mt-5 lg:mt-0">{item.label}</div>
                </div>
              ))}
            </div>

            <div className="flex flex-row justify-between items-center lg:justify-start space-x-4 lg:space-x-24 py-6 lg:py-0">

              {logos.map((item, index) =>(
                <div className=" " key={index}>
                  <div className=" ">
                    <img src={item.localFile.publicURL} alt={item.alt}  className="" />
                  </div>

                </div>
              ))}

            </div>

          </div>
          <div className="lg:w-1/3 px-2 lg:px-0">
            {children}
          </div>
        </div>

      </div>
    </section>
  )
}

const Hubspot = ({ portal_id, form_id, title, sub_title, path }) => {

  const ready = (form) => {

    var containers = document.getElementsByClassName(
      "legal-consent-container"
    );

    for (var x = 0 ; x < containers.length ; x++  ) {
      var container = containers[x]

      var fieldset1 = container.closest("fieldset");
      var f = container.closest("form");

      f.append(fieldset1)

    };

  };

  // const swapElements = (elm1, elm2) => {
  //   var parent1, next1, parent2, next2;

  //   parent1 = elm1.parentNode;
  //   next1 = elm1.nextSibling;
  //   parent2 = elm2.parentNode;
  //   next2 = elm2.nextSibling;

  //   parent1.insertBefore(elm2, next1);
  //   parent2.insertBefore(elm1, next2);
  // };

  const redirect = (path + "/thank-you").replace('//', '/');

  return (
    <div className="" id="the-form">
      <div className="container p-0 lg:p-0 ">
        <div className="bg-white text-grey  rounded-xl py-10 px-4 xl:px-10 shadow-[0_3px_20px_rgba(0,0,0,0.1)] max-w-fit  space-y-3">
          <h2 className="text-blue  text-2xl lg:subtitle-m-bold font-bold  text-center" >
            {title}
          </h2>
          <p className=" text-sm text-center">{sub_title}</p>
          <HubspotForm
            portalId={portal_id}
            formId={form_id}
            //cssRequired=""
            css=" " // !important
            cssClass="sigthfull-hubspot"
            submitButtonClass="sigthfull-hubspot-submit-btn c-btn landing-btn "
            errorClass="sigthfull-hubspot-error"
            errorMessageClass="sigthfull-hubspot-error-message"
            //onSubmit={(e) => console.log('Submit!')}
            //onFromSubmit={(e) => console.log('on form submit')}
            //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
            onReady={ready}
            //loading={<div>Loading...</div>}
            redirectUrl={redirect}
          />
        </div>
      </div>
    </div>
  );
};

const Section1 = ({data}) => {

  const {title, description, label, job , image , relationships  } = data;

  return (
    <section className="bg-dark-blue  pt-16 pb-16 ">
      <div className="container bg-white lg:rounded-xl">
        <div className="flex flex-col lg:flex-row justify-between  lg:mx-40 py-16 lg:space-x-32  ">
          <div className="lg:w-1/3">

            <img
              src={relationships?.image?.localFile?.publicURL}
              //height={image.height * 0.75}
              //width={image.width * 0.75}
              alt={`${image?.alt}`}
              className="block mb-6"
            />

            <h3 className="text-2xl text-[#434D56] w-[319px]">{title}</h3>
      </div>

      <div className="relative space-y-8 text-[#8F90A6] text-lg">

        <div className="absolute left-[-3rem] top-[-7rem] hidden lg:block" >
          <img src="/background/quote1.svg" alt="decoration" />
        </div>

        <div className=" testimonial-desc" dangerouslySetInnerHTML={{ __html: description.processed }} />
        <div>{label} | {job}</div>

        <div className="absolute bottom-[-6rem] right-[-2rem] hidden lg:block">
          <img src="/background/quote2.svg" alt="decoration" />
        </div>

      </div>
    </div>
      </div>
    </section>
  )
}

const Section2 = ({items}) => {

  const blocksDesktop = items.map((item, index) => {

    const sidea = <div className="space-y-6 max-w-[500px]">
      <h3 className="text-4xl font-bold">{item.title}</h3>
      <div className="text-xl" dangerouslySetInnerHTML={{__html:item.description_html.processed }} />
    </div>

    const sideb = <img src={item.relationships.image.localFile.publicURL} alt={item.image.alt} />

    return (
      <div key={index} className="flex flex-col lg:flex-row justify-between items-center space-x-32  ">
        <div className="lg:w-1/2">
          {index % 2 === 0 ? sidea : sideb }
        </div>
        <div className="lg:w-1/2">
        {index % 2 === 0 ? sideb : sidea }
        </div>
      </div>
    )

  })

  const blocksMobile = items.map((item, index) => {

    return (
      <div key={index} className="space-y-6">
        <img src={item.relationships.image.localFile.publicURL} alt={item.image.alt} />
        <div className="space-y-4">
          <h3 className="text-2xl font-bold">{item.title}</h3>
          <div className="text-lg" dangerouslySetInnerHTML={{__html:item.description_html.processed }} />
        </div>
      </div>
    )

  })

  return (
    <section className="bg-dark-blue text-white pt-16 pb-16 ">
      <div className="container ">
        <div className="hidden lg:block space-y-16">{blocksDesktop}</div>
        <div className="lg:hidden space-y-16">{blocksMobile}</div>
      </div>
    </section>
  )
}

const Section3 = ({title, items}) => {

  const blocks = items.map((item,index) => (
    <div className="space-y-4 lg:space-y-6 lg:w-3/12" key={index}>
      <img src={item.relationships.image.localFile.publicURL} alt={item.image.alt} className="h-[60px]" />
      <h3 className="text-xl lg:text-[22px] font-bold lg:leading-[26px]">{item.title}</h3>
      <div>{item.description}</div>
    </div>
  ))

  return (
    <section className="bg-dark-blue text-white lg:pt-24 pb-16 ">
      <div className="container space-y-8 lg:space-y-24">

        <h2 className="lg:text-center text-3xl lg:text-[46px] font-bold lg:font-medium">{title}</h2>
        <div className="flex flex-col lg:flex-row justify-between space-y-12 lg:space-y-0 lg:space-x-16 ">
          {blocks}
        </div>
      </div>
    </section>
  )
}



// const Section4 = ({title, button}) => {

//   return (
//     <section className="hidden lg:block text-white py-12 lg:py-16 bg-center lg:bg-[url('/background/CAT-BG.png')] bg-[url('/background/CAT-BG-Mobile.png')]">
//       <div className="container space-y-12 lg:space-y-20">

//         <h2 className="text-center text-3xl lg:text-[46px] lg:leading-[56px] font-bold lg:font-medium lg:w-[800px]  mx-auto ">{title}</h2>
//         <div className="mx-auto w-fit mb-24 lg:mb-4">
//           <a href="#the-form" className="mx-auto border rounded-full text-xl font-semibold	 text-center mx-auto px-9 py-4">
//             {button}
//           </a>
//         </div>
//       </div>
//     </section>
//   )
// }


const SectionFooter = () => {

  const { legal, options } = useFooter()
  const today = new Date();

  return (

    <div className="container bg-white flex flex-col lg:flex-row lg:justify-center text-sm lg:text-xs my-6 ">

      {legal.map((item, index) => (
        <Link key={index} to={item.url} className="lg:mx-4 text-slate-700">{item.label}</Link>
      ))}

      <div className="w-[125px] hidden lg:block"></div>

      <div className="text-[#828282] text-xs my-4 lg:my-0" >© {today.getFullYear()} {options.copyright}</div>

      <div className="w-[125px] hidden lg:block"></div>

      <div className="flex flex-row">
        {options.relationships.socials.map((m, i) => (
          <div key={i} className="">
            <a href={m.link.uri} target="_blank" rel="noreferrer">
              <img src={m.relationships.image.localFile.publicURL} alt={m.image.alt} width="18px" height="18px" loading="lazy" className="mr-0.5" />
            </a>
          </div>
        ))}
      </div>

    </div>

  )

}
export default Landing


export const query = graphql`
  query landing($id: String!) {
    node: nodeBookADemoNvp(id: { eq: $id }) {
      id
      title
      title1: field_title
      subtitle: field_title2
      section3Title : field_title3


      ctaBody: field_title_4
      ctaButton: field_title4

      description: field_description2

      calendarUrl : field_title_6


      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path{
        alias
      }
      logos: field_logos {
        alt
        title
      }
      relationships {

        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        section3Items: field_paragraph_repeter{
          ...ParagraphImageAndText
        }
        blocks: field_paragraphs_blocks{
          ...ParagraphGenericBlock
        }

        logos: field_logos {
          localFile {
            url
            publicURL
          }
        }

        testimonial: field_paragraph_single {
          paragraph_type {
            drupal_internal__target_id
          }
          title: field_title
          description: field_description_html {
            processed
          }
          label: field_label
          image: field_logo {
            alt
          }
          job: field_slug
          relationships {
            image: field_logo {
              id
              localFile {
                publicURL
              }
            }
          }
        }

        thankyou: field_content_block{
          ...ParagraphGenericBlock
        }

      }
    }
  }
`
